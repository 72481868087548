import { escape, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import DragDropContainer, {
  makeDefaultDragDropElementWrapper,
  DragDropContainerType
} from 'common/components/DragDropContainer';
import Button, { VARIANTS } from 'common/components/Button';
import { ViewColumn } from 'common/types/viewColumn';
import { getForgeIconNameForDataType } from 'common/views/dataTypeMetadata';
import I18n from 'common/i18n';
import {
  getCurrentMetadata,
  hasData,
  getDisplayableColumns
} from 'common/authoring_workflow/selectors/metadata.js';
import { ForgeAutocomplete, ForgeIcon, ForgeTextField } from '@tylertech/forge-react';
import {
  addTableColumn,
  removeTableColumn,
  selectAllTableColumns,
  resetAllColumns,
  reorderTableColumns
} from 'common/authoring_workflow/actions.js';
import { getTableColumns } from 'common/authoring_workflow/selectors/vifAuthoring.js';
import ForgeSelectedColumnItem from './ForgeSelectedColumnItem';

// Constants
const scope = 'shared.visualizations.panes.data';

interface Props {
  metadata: any;
  onAddTableColumn: (tableColumnName: string, columns: ViewColumn[]) => void;
  onRemoveTableColumn: (index: number) => void;
  onSelectAllTableColumns: (columns: ViewColumn[]) => void;
  onResetAllColumns: () => void;
  onReorderTableColumns: (newColumnOrder: ViewColumn[]) => void;
  vifAuthoring: any;
  selectedColumns: ViewColumn[];
}

/**
 * @returns The column objects selected by the user, either in the order that the user selected or in the order from the dataset.
 */
function pullSelectedColumnsFromDisplayableColumns(
  allColumns: ViewColumn[],
  displayedColumnFieldNames: string[],
  preserveUserOrder = true
): ViewColumn[] {
  if (preserveUserOrder)
    return displayedColumnFieldNames
      .map((displayedColumnFieldName) => {
        return allColumns.find((column: ViewColumn) => column.fieldName === displayedColumnFieldName);
      })
      .filter((column): column is ViewColumn => !!column);
  return allColumns.filter((column: ViewColumn) => displayedColumnFieldNames.includes(column.fieldName));
}

/**
 * @param values Array of column fieldNames that are currently selected
 */
export function replaceColumns(values: string[], props: Props) {
  const { onSelectAllTableColumns, metadata } = props;
  const allColumns: ViewColumn[] = getDisplayableColumnsWithAdditionalAttributes(metadata);
  const newSelectedColumns: ViewColumn[] = pullSelectedColumnsFromDisplayableColumns(allColumns, values);
  onSelectAllTableColumns(newSelectedColumns);
}

// Before adding to VIF, add additional properties to column metadata
function getDisplayableColumnsWithAdditionalAttributes(metadata: any): ViewColumn[] {
  return getDisplayableColumns(metadata).map((column: ViewColumn) => {
    return {
      ...column,
      hide: false // Default visibility for tables
    };
  });
}

const TableColumnsSelector: React.FC<Props> = (props) => {
  const { metadata, selectedColumns } = props;

  function handleSelectAllColumns() {
    const { onSelectAllTableColumns } = props;
    const allColumns = getDisplayableColumnsWithAdditionalAttributes(metadata);

    onSelectAllTableColumns(allColumns);
  }

  function renderSelectedColumnsList() {
    if (selectedColumns.length === 0) {
      return null;
    }

    const columnListItems = selectedColumns.map((column, index) => {
      return (
        <ForgeSelectedColumnItem
          column={column}
          index={index}
          key={index}
          onRemoveTableColumn={props.onRemoveTableColumn}
          vifAuthoring={props.vifAuthoring}
        />
      );
    });

    const wrappedItems = makeDefaultDragDropElementWrapper(columnListItems);
    return (
      <div className={cx('table-columns-list')}>
        <DragDropContainer
          type={DragDropContainerType.FORGE_LIST}
          items={selectedColumns}
          className="table-dragdrop-column-list"
          onDrop={props.onReorderTableColumns}
          childElements={wrappedItems}
        />
      </div>
    );
  }

  if (!hasData(metadata)) return null;

  const allDisplayableColumns = getDisplayableColumns(metadata);

  // Reset All button should only be disabled if no columns are currently selected.
  const resetDisabled = selectedColumns.length === 0;
  const resetAllButton = (
    <div data-testid="reset-all-button" className="reset-all-button">
      <Button
        aria-label={I18n.t('fields.columns.reset_aria_label', { scope })}
        className="reset-all"
        disabled={resetDisabled}
        variant={VARIANTS.LINK}
        onClick={() => props.onResetAllColumns()}
      >
        {I18n.t('fields.columns.reset', { scope })}
      </Button>
    </div>
  );

  // Select All button should only be disabled if all columns are already selected.
  const selectAllDisabled = selectedColumns.length === allDisplayableColumns.length;
  const selectAllButton = (
    <div
      data-testid="select-all-button"
      className="select-all-button"
      aria-label={I18n.t('fields.columns.select_all', { scope })}
    >
      <Button
        className="select-all"
        disabled={selectAllDisabled}
        variant={VARIANTS.LINK}
        onClick={() => handleSelectAllColumns()}
      >
        {I18n.t('fields.columns.select_all', { scope })}
      </Button>
    </div>
  );

  const selectedValues = selectedColumns.map((column) => {
    return {
      value: column.fieldName,
      label: column.name
    };
  });

  const onSelectOption = function (/*event: React.ChangeEvent<HTMLInputElement>*/) {
    replaceColumns(this.value, props);
  };

  function optionBuilder(option: { label: string }) {
    return `
      <span>${escape(option.label)}</span>
      <forge-tooltip position="bottom">${escape(option.label)}</forge-tooltip>
    `;
  }

  return (
    <div className="column-selector-container">
      <div className="column-selector-header">
        <span className="socrata-icon-column" />
        <label className="block-label table-column-selector-title" htmlFor="table-column-selector">
          {I18n.t('fields.columns.title', { scope })}
        </label>
        <div className="bulk-column-actions-container">
          {resetAllButton}
          {selectAllButton}
        </div>
      </div>
      <ForgeAutocomplete
        allowUnmatched={false}
        popupClasses="table-column-selector-options"
        debounce={500}
        filterOnFocus={true}
        mode="default"
        multiple={true}
        observeScroll={false}
        observeScrollThreshold={0}
        onSelect={onSelectOption}
        value={selectedValues}
        filter={(filterText: any) => {
          const columns = sortBy(getDisplayableColumns(metadata), 'name');
          const filtered = columns.filter(({ name }: { name: string }) => {
            return name && name.toLowerCase().includes(filterText.toLowerCase());
          });
          return filtered.map((column: any) => {
            return {
              value: column.fieldName,
              label: column.name,
              leadingIcon: getForgeIconNameForDataType(column.dataTypeName),
              leadingIconType: 'component'
            };
          });
        }}
        syncPopupWidth
        optionBuilder={optionBuilder}
      >
        <ForgeTextField>
          <input type="text" data-testid="add-column" id="add-column" />
          <label htmlFor="add-column"> {I18n.t('fields.columns.add_value', { scope })} </label>
          <ForgeIcon
            slot="trailing"
            data-forge-dropdown-icon
            name="arrow_drop_down"
            data-testid="column-selector-dropdown-arrow"
          />
        </ForgeTextField>
      </ForgeAutocomplete>
      {renderSelectedColumnsList()}
    </div>
  );
};

const mapDispatchToProps = {
  onAddTableColumn: addTableColumn,
  onRemoveTableColumn: removeTableColumn,
  onSelectAllTableColumns: selectAllTableColumns,
  onResetAllColumns: resetAllColumns,
  onReorderTableColumns: reorderTableColumns
};

const mapStateToProps = (state: any) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring,
  selectedColumns: getTableColumns(state.vifAuthoring)
});

export { TableColumnsSelector }; // Used for tests because enzyme does not like testing connected components;
export default connect(mapStateToProps, mapDispatchToProps)(TableColumnsSelector);
