// Vendor Imports
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Project Imports
import {
  setColorByColumn,
  setColorPaletteProperties,
  setResizeByColumn,
  setXAxisColumn,
  setYAxisColumn
} from '../../../actions';
import {
  getCurrentMetadata,
  getNonGeoLocationColumns,
  getNumericalColumns,
  getValidMeasures,
  hasData
} from '../../../selectors/metadata';
import {
  getColorByColumn,
  getColorPaletteGroupingColumnName,
  getResizeByColumn,
  getSeriesZero,
  getXAxisColumn,
  getYAxisColumn
} from '../../../selectors/vifAuthoring';
import { NULL_COLUMN_NAME } from 'common/authoring_workflow/constants';
import I18n from 'common/i18n';
import { getIconClassForDataType } from 'common/views/dataTypeMetadata';
import AutocompleteColumnSelector from 'common/authoring_workflow/components/shared/AutocompleteColumnSelector';

const scope = 'shared.visualizations.panes.data.fields.scatter_chart_limit';

export class ScatterChartOptionsSelector extends Component {
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { onSetColorPaletteProperties, vifAuthoring } = this.props;
    const nextVifAuthoring = nextProps.vifAuthoring;
    const thisVizType = vifAuthoring.authoring.selectedVisualizationType;
    const nextVizType = nextVifAuthoring.authoring.selectedVisualizationType;
    const thisSeries = getSeriesZero(vifAuthoring);
    const nextSeries = getSeriesZero(nextVifAuthoring);

    const currentColorPaletteGroupingColumnName = getColorPaletteGroupingColumnName(vifAuthoring);
    const nextColorPaletteGroupingColumnName = getColorPaletteGroupingColumnName(nextVifAuthoring);
    const propertyPairs = [
      [thisVizType, nextVizType],
      [currentColorPaletteGroupingColumnName, nextColorPaletteGroupingColumnName],
      [_.get(thisSeries, 'dataSource'), _.get(nextSeries, 'dataSource')],
      [_.get(thisSeries, 'color.palette'), _.get(nextSeries, 'color.palette')]
    ];
    const needsUpdate = _.some(propertyPairs, (pair) => !_.isEqual(...pair));

    // Whenever the properties related to color by (geometry) changes, we need to recompute
    // the buckets for custom color palette. Custom color palette displays the label from each bucket
    // next to a color picker to customize it.
    if (needsUpdate && !_.isNull(nextColorPaletteGroupingColumnName)) {
      onSetColorPaletteProperties();
    }
  }

  renderColumnOption = (option) => {
    const iconClassForDataType = getIconClassForDataType(option.type);

    return (
      <div className="dataset-column-selector-option">
        <span className={iconClassForDataType}></span> {option.title}
      </div>
    );
  };

  renderScatterChartDropdown = (dropDownOptions = {}) => {
    const { dropDownId, optionsData, onSelection, defaultValue, labelName } = dropDownOptions;
    const columnOptions = _.map(optionsData, column => ({
      title: column.name,
      value: column.fieldName,
      type: column.renderTypeName,
      render: this.renderColumnOption
    }));
    const options = [
      {
        title: I18n.t('no_value', { scope }),
        value: NULL_COLUMN_NAME
      },
      ...columnOptions
    ];

    const dropDownContainerClassName = `${dropDownId}-dropdown-container`;
    const dropDownAttributes = {
      id: dropDownId,
      placeholder: I18n.t('no_value', { scope }),
      options,
      onSelection: (option, index) => {
      if (option.value === NULL_COLUMN_NAME) {
          option.value = null;
        }
        onSelection(option, index);
      },
      // Forge Autocomplete does not call onSelect if option.value = null.
      // We pass it as a string so that the user can select the option.
      value: defaultValue === null ? NULL_COLUMN_NAME : defaultValue
    };

    return (
      <div className="authoring-field">
        <label
          className="block-label"
          htmlFor="scatter-chart">{I18n.t(`${labelName}`, { scope })}</label>
        <div className={dropDownContainerClassName}>
          <AutocompleteColumnSelector {...dropDownAttributes} />
        </div>
      </div>
    );
  }

  renderScatterChartSizeSelector() {
    const { onSetResizeByColumn, metadata, vifAuthoring } = this.props;
    const resizeByDropDownOptions = {
      dropDownId: 'resize-by-value-dropdown',
      optionsData: getNumericalColumns(metadata),
      onSelection: (option) => onSetResizeByColumn(option.value),
      defaultValue: getResizeByColumn(vifAuthoring),
      labelName: 'resize_by_value'
    };

    return this.renderScatterChartDropdown(resizeByDropDownOptions);
  }

  renderScatterChartColorSelector() {
    const { onSetColorByColumn, metadata, vifAuthoring } = this.props;
    const colorByDropDownOptions = {
      dropDownId: 'color-by-value-dropdown',
      optionsData: getNonGeoLocationColumns(metadata),
      onSelection: (option) => onSetColorByColumn(option.value),
      defaultValue: getColorByColumn(vifAuthoring),
      labelName: 'color_by_value'
    };

    return this.renderScatterChartDropdown(colorByDropDownOptions);
  }

  renderXAxisSelector() {
    const { onSetXAxisColumn, metadata, vifAuthoring } = this.props;
    const xAxisDropDownOptions = {
      dropDownId: 'x-axis-dropdown',
      optionsData: getNonGeoLocationColumns(metadata),
      onSelection: (option) => onSetXAxisColumn(option.value),
      defaultValue: getXAxisColumn(vifAuthoring),
      labelName: 'x_axis'
    };

    return this.renderScatterChartDropdown(xAxisDropDownOptions);
  }

  renderYAxisSelector() {
    const { onSetYAxisColumn, metadata, vifAuthoring } = this.props;
    const yAxisDropDownOptions = {
      dropDownId: 'y-axis-dropdown',
      optionsData: getValidMeasures(metadata),
      onSelection: (option) => onSetYAxisColumn(option.value),
      defaultValue: getYAxisColumn(vifAuthoring),
      labelName: 'y_axis'
    };

    return this.renderScatterChartDropdown(yAxisDropDownOptions);
  }

  renderChartOptions() {
    return (
      <div className="scatter-chart-option-container">
        {this.renderXAxisSelector()}
        {this.renderYAxisSelector()}
        {this.renderScatterChartColorSelector()}
        {this.renderScatterChartSizeSelector()}
      </div>
    );
  }

  render() {
    const shouldRender = hasData(this.props.metadata);
    return shouldRender ? this.renderChartOptions() : null;
  }
}

ScatterChartOptionsSelector.propTypes = {
  metadata: PropTypes.object,
  onSetColorByColumn: PropTypes.func,
  onSetColorPaletteProperties: PropTypes.func,
  onSetResizeByColumn: PropTypes.func,
  onSetXAxisColumn: PropTypes.func,
  onSetYAxisColumn: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorByColumn: setColorByColumn,
  onSetColorPaletteProperties: setColorPaletteProperties,
  onSetResizeByColumn: setResizeByColumn,
  onSetXAxisColumn: setXAxisColumn,
  onSetYAxisColumn: setYAxisColumn
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(ScatterChartOptionsSelector);
