// Vendor Imports
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FeatureFlags from 'common/feature_flags';

// Project Imports
import {
  setColorPalette,
  setColorPaletteProperties,
  setPrimaryColor,
  swapColorPalette,
  updateCustomColorPalette
} from '../../../actions';
import { getMeasureTitle } from '../../../helpers';
import {
  getCurrentMetadata,
  hasData,
  isDimensionTypeCheckbox
} from '../../../selectors/metadata';
import * as selectors from '../../../selectors/vifAuthoring';
import ColorPalettePicker from 'common/components/ColorPalettePicker';
import ColorPicker from 'common/components/ColorPicker';
import SwapControls from '../../shared/SwapControls';
import I18n from 'common/i18n';

// Constants
import {
  COLOR_PALETTES,
  COLORS,
  DEFAULT_COLOR_PALETTE
} from '../../../constants';

const scope = 'shared.visualizations.panes.presentation';

export class ColorPaletteSelector extends Component {
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const thisVizType = this.props.vifAuthoring.authoring.selectedVisualizationType;
    const nextVizType = nextProps.vifAuthoring.authoring.selectedVisualizationType;
    const thisSeries = _.get(this.props.vifAuthoring.vifs[thisVizType], 'series[0]');
    const nextSeries = _.get(nextProps.vifAuthoring.vifs[nextVizType], 'series[0]');

    if (!_.isEqual(thisVizType, nextVizType) ||
      !_.isEqual(_.get(thisSeries, 'dataSource'), _.get(nextSeries, 'dataSource')) ||
      !_.isEqual(_.get(thisSeries, 'color.palette'), _.get(nextSeries, 'color.palette'))) {
      this.props.onSetColorPaletteProperties();
    }
  }

  renderMultiSeriesCustomColorSelector = () => {
    const { metadata, onSetPrimaryColor, vifAuthoring } = this.props;

    if (!hasData(metadata)) {
      return null;
    }

    const nonFlyoutSeries = selectors.getNonFlyoutSeries(vifAuthoring).map((item, index) => {
      return _.extend({ seriesIndex: index }, item);
    });

    const colorSelectors = nonFlyoutSeries.map((item) => {
      const colorPickerAttributes = {
        palette: COLORS,
        value: item.color.primary,
        handleColorChange: (primaryColor) => onSetPrimaryColor(item.seriesIndex, primaryColor)
      };
      const title = getMeasureTitle(metadata, item);

      return (
        <div className="custom-color-container" key={item.seriesIndex}>
          <ColorPicker {...colorPickerAttributes} />
          <label className="color-value">{title}</label>
        </div>
      );
    });

    return (
      <div className="custom-palette-container">
        {colorSelectors}
      </div>
    );
  }

  renderSingleSeriesCustomColorSelector = () => {
    const { metadata, onUpdateCustomColorPalette, vifAuthoring, onSwapColorPalette } = this.props;
    const dimensionColumnName = selectors.getColorPaletteGroupingColumnName(vifAuthoring);
    const customColorPalette = selectors.getCustomColorPalettes(vifAuthoring);
    const customPaletteSelected = selectors.hasCustomColorPalette(vifAuthoring);
    const hasCustomPaletteGrouping = _.has(customColorPalette, dimensionColumnName);
    const hasCustomColorPaletteError = selectors.getCustomColorPaletteError(vifAuthoring);
    const showNullsAsFalse = selectors.getShowNullsAsFalse(vifAuthoring);
    const isDimensionCheckbox = isDimensionTypeCheckbox(metadata, { columnName: dimensionColumnName });

    if (hasCustomPaletteGrouping && !hasCustomColorPaletteError) {
      const customPaletteIndexs = _.map(customColorPalette[dimensionColumnName], 'index');
      const maximumIndex = _.max(customPaletteIndexs);

      // EN-41405: These controls don't work for the pie chart legend. Until we can do the work to replace
      // the pie chart legend with the legend used by other chart types, just hide these controls.
      const shouldShowSwapControls = selectors.getSelectedVisualizationType(vifAuthoring) !== 'pieChart';

      const colorSelectors = _.chain(customColorPalette[dimensionColumnName]).
        map((paletteValue, paletteKey) => ({ color: paletteValue.color, index: paletteValue.index, group: paletteKey, label: paletteValue.label })).
        filter((palette) => palette.index > -1).
        sortBy('index').
        map((palette) => {
          const customColorPaletteAttributes = {
            handleColorChange: (selectedColor) => {
              onUpdateCustomColorPalette(selectedColor, palette.group, dimensionColumnName);
            },
            palette: COLORS,
            value: palette.color
          };
          const swapControlAttributes = {
            className: 'custom-color-picker-swap-button',
            onClick: (fromIndex, toIndex) => {
              onSwapColorPalette(dimensionColumnName, fromIndex, toIndex);
            },
            index: palette.index,
            showUpIcon: (palette.index !== 0),
            showDownIcon: (palette.index !== maximumIndex)
          };

          const key = palette.label;
          const shouldDisplayNullsAsFalse =
            isDimensionCheckbox &&
            showNullsAsFalse &&
            (palette.group === I18n.t('shared.visualizations.charts.common.no_value'));

          if (shouldDisplayNullsAsFalse) {
            palette.label = I18n.t('shared.visualizations.charts.common.false_value');
          }

          return (
            <div className="custom-color-container" key={key}>
              <ColorPicker {...customColorPaletteAttributes} />
              <label className="color-value">{palette.label}</label>
              {shouldShowSwapControls && <SwapControls {...swapControlAttributes} />}
            </div>
          );
        }).
        value();

      return (
        <div className="custom-palette-container">
          {colorSelectors}
        </div>
      );
    } else if (customPaletteSelected && hasCustomColorPaletteError) {
      return (
        <div className="custom-color-palette-error alert error">
          {I18n.t('custom_color_palette_error', { scope })}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      colorPalettes,
      onSetColorPalette,
      onSetColorPaletteProperties,
      vifAuthoring
    } = this.props;
    const colorPaletteFromVif = selectors.getColorPalette()(vifAuthoring);
    const hasMultipleNonFlyoutSeries = selectors.hasMultipleNonFlyoutSeries(vifAuthoring);
    let colorPaletteValue;
    let customColorSelector;

    // If single-series and palette=='custom', set colorPaletteValue = colorPaletteFromVif
    // and render the single-series custom color picker.
    //
    if (!hasMultipleNonFlyoutSeries && colorPaletteFromVif === 'custom') {

      colorPaletteValue = colorPaletteFromVif;
      customColorSelector = this.renderSingleSeriesCustomColorSelector();

    } else if (hasMultipleNonFlyoutSeries && colorPaletteFromVif === null) {

      // If multi-series and palette is null, set colorPaletteValue = 'custom'
      // and render the multi-series custom color picker.
      //
      colorPaletteValue = 'custom';
      customColorSelector = this.renderMultiSeriesCustomColorSelector();

    } else if ((colorPaletteFromVif === null)) {

      colorPaletteValue = DEFAULT_COLOR_PALETTE;

    } else {

      colorPaletteValue = colorPaletteFromVif;
    }

    const colorPalettesWithCustomOption = [
      ...colorPalettes,
      {
        title: I18n.t('custom', { scope: 'shared.visualizations.color_palettes' }),
        value: 'custom'
      }
    ];

    const colorPaletteAttributes = {
      onSelectColorPalette: (colorPalette) => {

        // Custom colors for multi-series do not use the custom color palette.
        // Instead, it uses the primary color on each of the series.  So, when
        // choosing 'Custom' when it is multi-series, we actually set the color
        // palette to null instead of 'custom',
        const palette = (hasMultipleNonFlyoutSeries && (colorPalette == 'custom')) ?
          null :
          colorPalette;

        onSetColorPalette(palette);
        onSetColorPaletteProperties();
      },
      options: colorPalettesWithCustomOption,
      showCharm: false,
      value: colorPaletteValue
    };

    return (
      <div>
        <label className="block-label" htmlFor="color-palette">
          {I18n.t('fields.color_palette.title', { scope })}
        </label>
        <div className="color-scale-dropdown-container">
          <ColorPalettePicker {...colorPaletteAttributes} />
        </div>
        {customColorSelector}
      </div>
    );
  }
}

ColorPaletteSelector.defaultProps = {
  colorPalettes: COLOR_PALETTES
};

ColorPaletteSelector.propTypes = {
  metadata: PropTypes.object,
  onSetColorPalette: PropTypes.func,
  onSetColorPaletteProperties: PropTypes.func,
  onSwapColorPalette: PropTypes.func,
  onSetPrimaryColor: PropTypes.func,
  onUpdateCustomColorPalette: PropTypes.func,
  vifAuthoring: PropTypes.object
};

const mapDispatchToProps = {
  onSetColorPalette: setColorPalette,
  onSetColorPaletteProperties: setColorPaletteProperties,
  onSwapColorPalette: swapColorPalette,
  onSetPrimaryColor: setPrimaryColor,
  onUpdateCustomColorPalette: updateCustomColorPalette
};

const mapStateToProps = (state) => ({
  metadata: getCurrentMetadata(state.metadataCollection, state.vifAuthoring),
  vifAuthoring: state.vifAuthoring
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorPaletteSelector);
