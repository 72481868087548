import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

// components
import TableHierarchyGroupingHeader from './components/TableHierarchyGroupingHeader';
import TableHierarchyGroupingColumn from './components/TableHierarchyGroupingColumn';
import {
  ForgeAccordionContainer as AccordionContainer,
  ForgeAccordionPane as AccordionPane
} from 'common/components/Accordion';
import DragDropContainer, {
  makeCustomDragDropElementWrapper,
  DragDropContainerType
} from 'common/components/DragDropContainer';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { getCurrentVif } from 'common/authoring_workflow/selectors/vifAuthoring';
import MetadataProvider, { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { ForgeCheckbox } from '@tylertech/forge-react';

// types
import { HierarchyColumnConfig } from 'common/visualizations/vif';
import { VifAuthoring } from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';

import { MAX_HIERARCHIES } from 'common/authoring_workflow/constants';

// hooks
import useTableHierarchyGroupingsHooks from './hooks';

// project constants
const scope = 'shared.visualizations.panes.data';

export interface TableHierarchyGroupingsProps {
  hierarchyIndex: number;
  vifAuthoring: VifAuthoring;
  // Does not seem to be used anywhere
  setActiveHierarchyId: (hierarchyId: string) => void;
  handleOnRearrangeHierarchy: (
    existingColumnConfigs: HierarchyColumnConfig[],
    hierarchyIndex: number,
    currentColumnConfigs?: HierarchyColumnConfig[]
  ) => void;
  handleOnUpdateHierarchy: (
    existingColumnConfigs: HierarchyColumnConfig[],
    hierarchyIndex: number,
    currentColumnConfig?: HierarchyColumnConfig
  ) => void;
  handleOnUpdateHierarchyName: (hierarchyIndex: number, name: string) => void;
  handleOnRemoveHierarchy: (hierarchyIndex: number) => void;
  handleOnResetHierarchy: (hierarchyIndex: number) => void;
  handleOnDuplicateHierarchy: (hierarchyIndex: number) => void;
  handleOnShowGrandTotal: (hierarchyIndex: number, isShowing: boolean) => void;
  handleOnShowSubTotal: (hierarchyIndex: number, isShowing: boolean) => void;
}

const isGrouping = (el: React.ReactElement): boolean => {
  return el.props.columnConfig?.isGrouping;
};

const flexibleHierarchiesEnabled = FeatureFlags.valueOrDefault('enable_flexible_table_hierarchies', false);

export const TableHierarchyGroupings = (props: TableHierarchyGroupingsProps) => {
  const [allColumnMetadata, setAllColumnMetadata] = useState<ViewColumn[]>([]);
  const [columnNonStandardAggregations, setColumnNonStandardAggregations] = useState<ColumnAggregation[]>([]);
  const { state, functions } = useTableHierarchyGroupingsHooks(props);

  const {
    existingColumnConfigs,
    existingColumnConfigsToRemove,
    combinedColumns,
    totalHierarchiesDefined,
    hierarchy,
    tableColumns
  } = state;

  const {
    setActiveHierarchy,
    handleOnUpdateHierarchy,
    handleOnRearrangeHierarchy,
    handleOnUpdateHierarchyName,
    handleOnDuplicateHierarchy,
    handleOnRemoveHierarchy,
    handleOnResetHierarchy,
    handleOnColumnsUpdatedRemoveHierarchies,
    handleOnAggregationSelection,
    handleOnShowGrandTotal,
    handleOnShowSubTotal
  } = functions;

  useEffect(() => {
    if (_.size(existingColumnConfigsToRemove) > 0) {
      handleOnColumnsUpdatedRemoveHierarchies();
    }
  }, [existingColumnConfigsToRemove.length]);

  useEffect(() => {
    async function updateColumnMetadata() {
      const vif = getCurrentVif(props.vifAuthoring);
      const metadataProvider = new MetadataProvider(
        {
          datasetUid: _.get(vif, 'series[0].dataSource.datasetUid'),
          domain: _.get(vif, 'series[0].dataSource.domain'),
          readFromNbe: _.get(vif, 'series[0].dataSource.readFromNbe', true)
        },
        true
      );
      const metadata = await metadataProvider.getDatasetMetadata();

      const aggregations = await metadataProvider.getAggregationSuggestions();
      setColumnNonStandardAggregations(aggregations);

      setAllColumnMetadata(metadata.columns || []);
    }

    updateColumnMetadata();
  }, [props.vifAuthoring]);

  const hierarchyGroupingColumnConfigs = existingColumnConfigs.filter(
    (columnConfig) => columnConfig.isGrouping
  );

  const accordianPaneTitle = (
    <TableHierarchyGroupingHeader
      columnMetadata={allColumnMetadata}
      hierarchy={hierarchy}
      duplicationAllowed={totalHierarchiesDefined < MAX_HIERARCHIES}
      removeAllowed={totalHierarchiesDefined > 1}
      setActiveHierarchy={setActiveHierarchy}
      hierarchyGroupingColumnConfigs={hierarchyGroupingColumnConfigs}
      handleOnDuplicateHierarchy={handleOnDuplicateHierarchy}
      handleOnResetHierarchy={handleOnResetHierarchy}
      handleOnRemoveHierarchy={handleOnRemoveHierarchy}
      handleOnUpdateHierarchyName={handleOnUpdateHierarchyName}
    />
  );

  const renderGroupingColumns = (columns: ViewColumn[]) => {
    const groupingColumns = columns.map((column: ViewColumn, columnIndex: number) => {
      const columnMetadata = allColumnMetadata.find((col) => col.fieldName === column.fieldName);
      const nonStandardAggregations = columnNonStandardAggregations.filter(
        (agg) => agg.fieldName === column.fieldName
      );
      const correspondingConfigForCurrentColumn: HierarchyColumnConfig | undefined = _.find(
        existingColumnConfigs,
        (h: HierarchyColumnConfig) => h.columnName === column.fieldName
      );

      return (
        <TableHierarchyGroupingColumn
          key={columnIndex}
          columnMetadata={columnMetadata || column}
          aggregations={nonStandardAggregations}
          columnIndex={columnIndex}
          hierarchyContainsGrouping={hierarchyGroupingColumnConfigs.length > 0}
          showGrandTotalChecked={hierarchy?.showGrandTotal}
          columnConfig={correspondingConfigForCurrentColumn}
          existingIsGroupingHierarchiesLength={
            _.filter(existingColumnConfigs, (e: HierarchyColumnConfig) => e.isGrouping).length
          }
          hierarchyIndex={props.hierarchyIndex}
          handleOnUpdateHierarchy={handleOnUpdateHierarchy}
          handleOnAggregationSelection={handleOnAggregationSelection}
        />
      );
    });

    const wrappedGroupingColumns = makeCustomDragDropElementWrapper(groupingColumns, isGrouping);

    return (
      <DragDropContainer
        type={DragDropContainerType.LIST}
        items={hierarchyGroupingColumnConfigs}
        className="table-hierarchy-dragdrop-column-list"
        onDrop={handleOnRearrangeHierarchy}
        childElements={wrappedGroupingColumns}
      />
    );
  };

  const ungroupedColumnsWarning = () => {
    let renderUngroupedColumnsWarning;
    if (tableColumns.length === existingColumnConfigs.length) {
      const ungroupedColumnConfigs = existingColumnConfigs.filter((columnConfig) => !columnConfig.isGrouping);
      renderUngroupedColumnsWarning = _.every(ungroupedColumnConfigs, ['hidden', true]);
    }

    return renderUngroupedColumnsWarning ? (
      <div className="ungrouped-columns-warning">
        <div className="warning-icon-container">
          <SocrataIcon name={IconName.Warning} />
        </div>
        <div className="warning-content">
          {I18n.t('fields.table_hierarchies.ungrouped_columns.warning', { scope })}
        </div>
      </div>
    ) : null;
  };

  const renderGrandTotals = () => {
    const checkboxAttributes = {
      id: `show-grand-total-${props.hierarchyIndex}-checkbox`,
      checked: hierarchy.showGrandTotal ?? false,
      onClick: () => handleOnShowGrandTotal(!hierarchy.showGrandTotal),
      'data-testid': 'table-show-grand-total-checkbox'
    };

    return (
      <div className="grand-totals-container">
        <ForgeCheckbox>
          <input type="checkbox" {...checkboxAttributes} readOnly />
          <label className="show-totals-label" slot="label">
            {I18n.t('fields.table_hierarchies.show_totals', { scope })}
          </label>
        </ForgeCheckbox>
      </div>
    );
  };

  const renderSubTotals = () => {
    const checkboxAttributes = {
      id: `show-subtotal-${props.hierarchyIndex}-checkbox`,
      checked: hierarchy.showSubTotal ?? false,
      onClick: () => handleOnShowSubTotal(!hierarchy.showSubTotal),
      'data-testid': 'table-show-subtotal-checkbox'
    };

    return (
      <div className="subtotals-container">
        <ForgeCheckbox>
          <input type="checkbox" {...checkboxAttributes} readOnly />
          <label className="show-subtotals-label" slot="label">
            {I18n.t('fields.table_hierarchies.show_subtotals', { scope })}
          </label>
        </ForgeCheckbox>
      </div>
    );
  };

  const hierarchiesListContainer = (
    <div className="table-hierarchy-list-container">
      <div className="list-headers">
        <div className="grouping-header">{I18n.t('fields.table_hierarchies.grouping', { scope })}</div>
        <div className="aggregation-header">{I18n.t('fields.table_hierarchies.aggregation', { scope })}</div>
      </div>
      {renderGroupingColumns(combinedColumns)}
    </div>
  );

  return (
    <AccordionContainer>
      <AccordionPane title={accordianPaneTitle} toggleOnCaretOnly onOpen={setActiveHierarchy}>
        <div className="table-hierarchies-list" data-testid="table-hierarchies-list">
          {!flexibleHierarchiesEnabled ? hierarchiesListContainer : null}
          <div
            className={cx('totals-container', { 'flexible-hierarchies-layout': flexibleHierarchiesEnabled })}
          >
            {renderGrandTotals()}
            {renderSubTotals()}
          </div>
          {ungroupedColumnsWarning()}
        </div>
      </AccordionPane>
    </AccordionContainer>
  );
};
